import home from "../pages/tos/index"
import qualification from '../pages/tos/qualification';
import xieyi from '../pages/tos/xieyi';
import fagui from '../pages/tos/fagui';
import down from '../pages/app/down.vue';
import license from '../pages/tos/license.vue'
import license1 from '../pages/tos/license1.vue'
import invite from '../pages/app/invite.vue'
import share from '../pages/app/share.vue'
export default[
  {
    path:"/",
    redirect:'/app/download'
  },
  {
    path:'/app/goods/:id',
    component:share,
    name:share
  },
  {
    path:'/app/invitation/:reg',
    component:invite,
    meta: {
      title: 'THE LESMO 优惠券',
      keepAlive: true, // 需要被缓存
  }
  },
  {
    path: "/app/download",
    component:down,
    name:down
  },
  {
    path:'/TOS/license/2',
    component:license1,
    name:license1,
    meta: {
      title: 'THE LESMO经营者资质信息公示',
      keepAlive: true, // 需要被缓存
  }
  },
  {
    path:'/TOS/license/1',
    component:license,
    name:license,
    meta: {
      title: 'THE LESMO经营者资质信息公示',
      keepAlive: true, // 需要被缓存
  }
    
  },
  {
    path: "/TOS/TOC",
    component: home,
    meta: {
      title: 'THE LESMO 时尚潮流电商平台协议与规定',
      keepAlive: true, // 需要被缓存
  }
  },
  {
    path:"/TOS/qualification",
    component:qualification,
    name:qualification,
    meta: {
      title: 'THE LESMO经营者资质信息公示',
      keepAlive: true, // 需要被缓存
  }
  },
  {
    path:"/TOS/privacy-policy",
    component:xieyi,
    name:xieyi,
    meta: {
      title: 'THE LESMO商城平台隐私政策',
      keepAlive: true, // 需要被缓存
  }
  },
  {
    path:"/TOS/service-agreement",
    component:fagui,
    name:fagui,
    meta: {
      title: 'THE LESMO平台服务协议',
      keepAlive: true, // 需要被缓存
  }
    
  }
]