import Vue from 'vue'
import App from './App.vue'
import router from "./router/index1";
import rem from "./static/rem"
import axios from 'axios'
import './static/font/Montserrat-Medium.ttf'
import wcSwiper from 'wc-swiper'
import 'wc-swiper/style.css'
import './static/font.css'
Vue.use(wcSwiper);
rem()
Vue.config.productionTip = false
axios.defaults.baseURL = '/api'
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.filter('price', function (value) {
	if (!value) return '0.00';
	var value = value.toFixed(2);//提前保留两位小数
	var intPart = Number(value).toFixed(0); // 获取整数部分
	var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 将整数部分逢三一断 ？？？
	var floatPart = '.00'; // 预定义小数部分
	value = value.toString();//将number类型转为字符串类型，方便操作
	var value2Array = value.split('.');
	if (value2Array.length === 2) { // =2表示数据有小数位
	  floatPart = value2Array[1].toString(); // 拿到小数部分
	  if (floatPart.length === 1) { // 补0,实际上用不着
		  return (intPartFormat + '.');
	  } else {
		  return (intPartFormat );
	  }
	} else {
	  return parseFloat(NumberintPartFormat );
	}
})
new Vue({
  render: h => h(App),
  router,
  rem
}).$mount('#app')
